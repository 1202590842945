

import router from './router';
import './index.css'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Layui from '@layui/layui-vue';
import '@layui/layui-vue/lib/index.css';
import './styles/default.css';


import { createPinia } from 'pinia';
import { createApp } from 'vue';


//主页
import App from './App.vue';

// 创建Vue应用实例

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(Layui);
app.mount('#app');
