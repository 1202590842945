import router from '@/router';
import api from '@/utils/api';
import { defineStore } from 'pinia'
/**
 * 存储用户信息
 */
export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: null,
        token: null,
    }),
    actions: {
        login(user, token) {
            this.user = user;
            this.token = token;
        },
        logout() {
            this.user = null;
            this.token = null;
            router.push("/login")
        },
        //刷新当前登录用户的信息
        refreshUserInfo(callback) {
            api.getUserInfo().then((res) => {
                if (!res || res.status != 200 || !res.data || res.data.code != 200 || !res.data.data) {
                    this.logout();
                    callback && callback({
                        message: '数据加载失败'
                    });
                }
                this.user = res.data.data;
                callback && callback('ok');
            }).catch((error) => {
                this.logout();
                callback && callback(error);
            });
        },
    },
    persist: {
        storage: localStorage,
        paths: ['user', 'token', 'locale', 'theme', 'colorThemes'],
        beforeRestore: (ctx) => {
            console.log(`即将恢复 '${ctx.store.$id}'`)
        },
        afterRestore: (ctx) => {
            console.log(`刚刚恢复完 '${ctx.store.$id}'`)
            if (ctx.store.token) {//刷新用户信息
                api.getUserInfo().then((res) => {
                    if (!res || res.status != 200 || !res.data || res.data.code != 200 || !res.data.data) {
                        ctx.logout();
                    }
                    ctx.store.user = res.data.data;
                }).catch((error) => {
                    ctx.store.logout();
                });
            }
        },
    },
})
