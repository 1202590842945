import { createRouter, createWebHashHistory } from 'vue-router';
import { useUserStore } from '@/stores/user'

// 系统路由信息
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('/src/views/home/Home.vue'),
        meta: {
            title: '首页',
            requiresAuth: true // 只有经过身份验证的用户才能访问
        }
    },
    {
        path: '/index',
        component: () => import('../views/index/index.vue'),
        meta: {
            requiresAuth: false,
            title: '回忆导航'
        }
    },
    {
        path: '/login',
        component: () => import('../views/login/Login.vue'),
        meta: {
            requiresAuth: false,
            title: '登录'
        }
    },
    {
        path: '/resetpwd',
        component: () => import('../views/resetpwd/Resetpwd.vue'),
        meta: {
            requiresAuth: false,
            title: '重置密码'
        }
    },
    {
        path: '/register',
        component: () => import('../views/register/Register.vue'),
        meta: {
            requiresAuth: false,
            title: '注册'
        }
    },
]

// 创建路由信息
const router = createRouter({
    history: createWebHashHistory(), // 使用 hash 模式
    routes, // 路由配置
})

// 添加路由守卫
router.beforeEach(async (to, from, next) => {
    // 获取用户状态管理
    const userStore = useUserStore()
    
    // 如果目标路由需要身份验证且用户未登录，重定向到登录页面
    if (to.meta.requiresAuth && !userStore.token) {
        next({ path: '/index' });
    } else if (to.path === '/register' || to.path === '/resetpwd' || to.path === '/login' && userStore.token) {
        // 如果用户已登录，禁止访问 /login 页面，重定向到首页
        next({ path: '/' });
    } else {
        // 如果目标路由不需要身份验证或者用户已登录，则直接访问目标路由
        document.title = "逸凡-" + to.meta.title;
        next();
    }
})

export default router;
