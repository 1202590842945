import axios from "axios";
//引入状态管理，获取登录后的token
import { useUserStore } from '@/stores/user'
import router from "@/router";
/**
 * 创建api实例
 */
 const api = axios.create({
    baseURL: "/api",
    timeout: 999999999,
    headers: {}
});
// 添加请求拦截器
api.interceptors.request.use(function (config) {
    // 数据状态管理
    const userStore = useUserStore()
    if (userStore && userStore.token) {
        //附带上token信息
        config.headers.token = userStore.token || '';
        config.headers['account'] = userStore.user.account || '';
        // config.headers.authorization = "Bearer " + userStore.token;
        // config.headers.auth = "Bearer " + userStore.token;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
api.interceptors.response.use(function (response) {
    // 数据状态管理
    const userStore = useUserStore()
    //刷新客户端的token信息
    if (response.headers.token && userStore.token != response.headers.token) {
        userStore.token = response.headers.token;
    }
    return response;
}, function (error) {
    switch (error.response.status) {
        case 401://提示先登录，自动转到登录界面
            // 数据状态管理
            const userStore = useUserStore()
            userStore.logout();
            router.push("/index")
            break;
        default:
            break;
    }
    return Promise.reject(error);
});
/**
 * 获取小程序二维码状态
 */
const getQrcodeStatus = (scene) => {
    return api.get('/scene?scene='+scene)
}
/**
 * 退出系统
 */
 const logout = () => {
    return api.get('/logout')
}
/**
 * 登录接口
 * @param {*} account 账号
 * @param {*} password 密码
 * @param {*} captcha 验证码
 */
const login = (account, password, captcha) => {
    return api.post('/login/password', {
        account: account,
        password: password,
        captcha: captcha
    })
}
/**
 * 获取短信验证码
 * @param {*} account 
 */
const getSmsCode = (account) => {
    return api.post('/login/getSmsCode', {
        account
    })
}
/**
 * 短信验证码登录
 * @param {*} account 
 * @param {*} smscode 
 * @param {*} captcha 
 * @returns 
 */
const loginBySmsCode = (account, smscode, captcha) => {
    return api.post('/login/smsCode', {
        account: account,
        smscode: smscode,
        captcha: captcha
    })
}
/**
 * 获取当前用户信息
 * @returns 
 */
const getUserInfo = () => {
    return api.get('/users/info')
}
/**
 * 获取短信验证码
 * @param {*} account 
 * @param {*} captcha 
 */
const getResetPhoneCode = (account, captcha) => {
    return api.get('/resetpwd/smscode?account=' + account + "&captcha=" + captcha)
}
/**
 * 校验找回密码短信验证码是否合法
 * @param {*} account 
 * @param {*} smsode 
 */
const validateResetpwdPhoneCode = (account, smscode) => {
    return api.post('/resetpwd/validate/smscode', {
        account, smscode
    })
}
/**
 * 通过短信验证码重置密码
 * @param {*} account 
 * @param {*} smsode 
 * @param {*} password 
 */
const resetpwdByPhoneCode = (account, smscode, password) => {
    return api.post('/resetpwd/reset/smscode', {
        account, smscode, password
    })
}
/**
 * 获取邮箱验证码
 * @param {*} account 
 * @param {*} captcha 
 * @returns 
 */
const getResetEmailCode = (account, captcha) => {
    return api.get('/resetpwd/emailcode?account=' + account + "&captcha=" + captcha)
}
/**
 * 验证邮箱验证码是否合法
 * @param {*} account 
 * @param {*} emailcode 
 * @returns 
 */
const validateResetpwdEmailCode = (account, emailcode) => {
    return api.post('/resetpwd/validate/emailcode', {
        account, emailcode
    })
}
/**
 * 通过邮箱验证码重置密码
 * @param {*} account 
 * @param {*} emailcode 
 * @param {*} password 
 * @returns 
 */
const resetpwdByEmailCode = (account, emailcode, password) => {
    return api.post('/resetpwd/reset/emailcode', {
        account, emailcode, password
    })
}
/**
 * 获取注册验证码
 * @param {*} phone 
 * @param {*} captcha 
 */
const getRegisterPhoneCode = (phone, captcha) => {
    return api.get('/register/smscode?phone=' + phone + "&captcha=" + captcha)
}
/**
 * 校验注册验证码是否合法
 * @param {*} phone 
 * @param {*} smscode 
 */
const validateRegisterPhoneCode = (phone, smscode) => {
    return api.post('/register/validate/smscode', {
        phone, smscode
    })
}
/**
 * 邮箱注册接口
 * @param {*} phone 
 * @param {*} smscode 
 * @param {*} account 
 * @param {*} name 
 * @param {*} sex 
 * @param {*} email 
 * @param {*} password 
 */
const registerByPhone = (phone, smscode, account, name, sex, email, password) => {
    return api.post('/register/phone', {
        phone, smscode, account, name, sex, email, password
    })
}
/**
 * 检测电话是否可用
 * @param {*} phone 
 */
const checkPhone = (phone) => {
    return api.get('/register/checkphone?phone=' + phone)
}
/**
 * 检测账号是否可用
 * @param {*} account 
 */
const checkAccount = (account) => {
    return api.get('/register/checkaccount?account=' + account)
}
/**
 * 检测邮箱是否可用
 * @param {*} email 
 */
const checkEmail = (email) => {
    return api.get('/register/checkemail?email=' + email)
}
export default {
    api,
    getUserInfo,getQrcodeStatus,
    login,logout,
    getSmsCode, loginBySmsCode,
    getResetPhoneCode, validateResetpwdPhoneCode, resetpwdByPhoneCode, getResetEmailCode, validateResetpwdEmailCode, resetpwdByEmailCode,
    getRegisterPhoneCode, validateRegisterPhoneCode, registerByPhone, checkPhone, checkAccount, checkEmail,
}

